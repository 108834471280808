
// Remmebr to update layoutVersion in LayoutManager when changing the default tabs!

import React from 'react'

import { Util } from '../base/util.js'
import { LineParser } from '../base/line.js'
import { LineBuffer } from '../base/lines.js'
import { LayoutVBox, LayoutCenter, GamePanel, LayoutBottom } from '../components/layouting'
import { TabAffDef } from '../components/tabs/affdef'
import { TabItemList } from '../components/tabs/itemlist'
import { TabMap } from '../components/tabs/map'
import { TabNews } from '../components/tabs/news'
import { TabOutput } from '../components/tabs/output'
import { TabSkills } from '../components/tabs/skills'
import { TabGauges } from '../components/tabs/gauges'
import { TabButtons } from '../components/tabs/buttons'
import { TabTasks } from '../components/tabs/tasks'
import { TabWho } from '../components/tabs/who'
import { TabCustom } from '../components/tabs/custom'
import { StatusBar } from '../components/statusbar'
import { ConfirmDialog, AlertDialog, InputDialog, PasswordDialog } from './../components/dialogs/dialogs'
import { EditorDialog } from './../components/dialogs/editor'
import { LoginDialog } from './../components/dialogs/login'
import { CharactersDialog } from './../components/dialogs/characters'
import { GameAddDialog } from './../components/dialogs/gameadd'
import { DisconnectedDialog } from './../components/dialogs/disconnected'
import { PopupDialog } from './../components/dialogs/popup'
import { CreationDialog } from './../components/dialogs/creation'
import { TextWindow } from './../components/dialogs/textwindow'
import { ContentWindow } from './../components/dialogs/contentwindow'
import { SettingsDialog } from './../components/dialogs/settings'
import { IntroDialog } from './../components/dialogs/intro'

class NexusLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = { nexus: props.nexus, confirmdlg: false, alertdlg: false, inputdlg: false, passworddlg: false, gameadddlg: false, logindlg: false, charactersdlg: false, gameseldlg: false, popupdlg: false, creationdlg: false, settingsdlg: false, textwindows: [], contentwindows: [] };
        this._textwindowId = 0;
        this._offsets = 0;
        this.settingsRef = React.createRef();
    }

    componentDidMount() {
        let m = this.props.manager;
        m.layout = this;

        if (m.wants_intro) {
            m.show_intro();
            m.wants_intro = false;
        }
        else if (m.wants_characters || ((!m.count_dialogs()) && (!this.props.nexus.datahandler().is_connected()))) {
            m.show_characters ();
            m.wants_characters = false;
        }
        
    }

    componentWillUnmount() {
        let m = this.props.manager;
        m.layout = null;
    }

    onCreation(name, pass, gameID, data) {
        let nex = this.props.nexus;
        nex.create_char(name, pass, gameID, data);
    }

    openTextWindow(dlg) {
        this._textwindowId++;
        dlg.id = this._textwindowId;
        dlg.offset = this._offsets;
        this._offsets++;
        let wins = this.state.textwindows;
        wins.push (dlg);
        this.setState({textwindows: wins});
    }

    closeTextWindow(id) {
        let wins = this.state.textwindows;
        for (let i = 0; i < wins.length; ++i)
            if (wins[i].id === id) {
                wins.splice (i, 1);  // remove this item
                break;
            }
        this.setState({textwindows: wins});

        let cwins = this.state.contentwindows;
        if ((!wins.length) && (!cwins.length)) this._offsets = 0;
    }

    renderDialogs() {
        let nex = this.props.nexus;

        let dialogs = [];
        let mobile = nex.platform().real_mobile();
        let manager = this.props.manager;

        let open = this.state.confirmdlg;
        let setOpen = (open) => this.setState({confirmdlg: open});
        let title = manager.confirmTitle;
        let content = manager.confirmText;
        let onConfirm = manager.confirmCallback;
        let overElement = this.state.settingsdlg ? this.settingsRef : null;
        if (open) dialogs.push ((<ConfirmDialog key="confirm_dialog" open={open} setOpen={setOpen} title={title} onConfirm={onConfirm} overElement={overElement}>{content}</ConfirmDialog>));

        open = this.state.alertdlg;
        setOpen = (open) => this.setState({alertdlg: open});
        title = manager.alertTitle;
        content = manager.alertText;
        let onClose = manager.alertCallback;
        if (open) dialogs.push ((<AlertDialog key="alert_dialog" open={open} setOpen={setOpen} title={title} onClose={onClose} overElement={overElement}>{content}</AlertDialog>));

        open = this.state.introdlg;
        setOpen = (open) => { this.setState({introdlg: open, charactersdlg: !open}); if (!open) manager.on_intro_close(); };
        if (open) dialogs.push ((<IntroDialog key="intro_dialog" open={open} setOpen={setOpen} onClose={()=>manager.on_intro_close()} onGameSelect={(gid)=>manager.on_intro_game_select(gid)} logging_in={manager.logging_in} nexus={nex} />));
        
        open = this.state.charactersdlg;
        let ongamelogin = this.state.ongamelogin;
        let onlogout = this.state.onlogout;
        let onchars_login = this.state.onchars_login;
        let onchars_register = this.state.onchars_register;
        let oncreation = this.state.oncreation;
        let onaddchar = this.state.onaddchar;
        let onrenamechar = this.state.onrenamechar;
        let onacctchange = this.state.onacctchange;
        let onacctremove = this.state.onacctremove;
        let chars = nex.serverapi();
        if (open) dialogs.push ((<CharactersDialog key="characters_dialog" open={open} chars={chars} nexus={nex} logging_in={manager.logging_in} loggedin={nex.logged_in_nexus()} ongamelogin={ongamelogin} oncreation={oncreation} onaddchar={onaddchar} onrenamechar={onrenamechar} onacctchange={onacctchange} onacctremove={onacctremove} onlogout={onlogout} onlogin={onchars_login} onregister={onchars_register} platform={nex.platform()} />));

        open = this.state.logindlg;
        setOpen = (open) => { this.setState({logindlg: open}); manager.show_characters(); };
        let onlogin = this.state.onlogin;
        let onregister = this.state.onregister;
        let onforgot = this.state.onforgot;
        let showregister = this.state.loginregister;
        let acctemail = this.state.loginemail;
        let acctpass = this.state.loginpass;
        if (open) dialogs.push ((<LoginDialog key="login_dialog" open={open} setOpen={setOpen} chars={chars} showregister={showregister} onlogin={onlogin} onregister={onregister} onforgot={onforgot} acctemail={acctemail} acctpass={acctpass} />));

        open = this.state.inputdlg;
        setOpen = (open) => this.setState({inputdlg: open});
        title = manager.inputTitle;
        let defaultText = manager.inputDefaultText;
        content = manager.inputText;
        onConfirm = manager.inputCallback;
        let label = manager.inputLabel;
        let okText = manager.inputOkText;
        if (open) dialogs.push ((<InputDialog key="input_dialog" open={open} setOpen={setOpen} title={title} defaultText={defaultText} okText={okText} label={label} onConfirm={onConfirm}>{content}</InputDialog>));
        
        open = this.state.passworddlg;
        setOpen = (open) => this.setState({passworddlg: open});
        title = manager.passwordTitle;
        content = manager.passwordText;
        onConfirm = manager.passwordCallback;
        okText = manager.passwordOkText;
        let remember = manager.passwordRemember;
        let defaultpwd = manager.passwordDefault;
        if (open) dialogs.push ((<PasswordDialog key="password_dialog" open={open} setOpen={setOpen} title={title} okText={okText} remember={remember} pass={defaultpwd} onConfirm={onConfirm}>{content}</PasswordDialog>));

        open = this.state.disconnecteddlg;
        setOpen = (open) => { this.setState({disconnecteddlg: open}); if (!open) manager.onCharacters(); };
        var reason = manager.disconnectReason;
        var onReconnect = manager.onReconnect;
        var onDownload = manager.onDownload;
        var onCharacters = manager.onCharacters;
        if (open) dialogs.push ((<DisconnectedDialog key="disconnected_dialog" open={open} setOpen={setOpen} onReconnect={onReconnect} onDownload={onDownload} onCharacters={onCharacters} reason={reason} />));

        for (let widx = 0; widx < this.state.textwindows.length; ++widx) {
            let dlg = this.state.textwindows[widx];
            if (!dlg) continue;
            open = dlg.open;
            let id = dlg.id;
            let header = dlg.header;
            let lines = dlg.lines;
            if (open) dialogs.push ((<TextWindow id={id} open={open} lines={lines} header={header} onClose={()=>{this.closeTextWindow(id)}} offsetId={dlg.offset} on_command={(cmd) => manager.command_from_tab (cmd, true) } nexus={nex} />));
        }

        for (let widx = 0; widx < this.state.contentwindows.length; ++widx) {
            let dlg = this.state.contentwindows[widx];
            if (!dlg) continue;
            open = dlg.open;
            if (open) dialogs.push ((<ContentWindow info={dlg} onClose={()=>{manager.close_content_window(dlg.id)}} offsetId={dlg.offset} on_command={(cmd) => manager.command_from_tab (cmd, true) } nexus={nex} />));
        }

        open = this.state.gameadddlg;
        setOpen = (open) => this.setState({gameadddlg: open});
        onConfirm = manager.gameaddCallback;
        if (open) dialogs.push ((<GameAddDialog key="gameadd_dialog" open={open} setOpen={setOpen} onConfirm={onConfirm} title={manager.gameaddTitle} server={manager.gameaddServer} port={this.props.manager.gameaddPort} isUpdate={manager.gameaddUpdate} />));

        open = this.state.popupdlg;
        setOpen = (open) => this.setState({popupdlg: open});
        if (open) dialogs.push ((<PopupDialog key="popup_dialog" open={open} setOpen={setOpen} {...this.state.popupProps} />));

        open = this.state.editordlg;
        setOpen = (open) => this.setState({editordlg: open});
        title = manager.editorTitle;
        content = manager.editorText;
        let onSave = (text) => { manager.onEditorSubmit(text); this.setState({editordlg: false}); };
        let onCancel = () => { manager.onEditorCancel(); this.setState({editordlg: false}); };
        if (open) dialogs.push ((<EditorDialog key="editor_dialog" open={open} onSave={onSave} onCancel={onCancel} text={content} />));

        open = this.state.creationdlg;
        onCancel = () => { this.setState({creationdlg:false, charactersdlg:true}); };
        let onCreation = (name, pass, gameID, data) => { this.onCreation(name, pass, gameID, data); };
        if (open) dialogs.push ((<CreationDialog key="creation_dialog" open={open} chars={chars} onCancel={onCancel} nexus={nex} platform={nex.platform()} onCreation={onCreation} />));

        open = this.state.settingsdlg;
        onCancel = () => { this.setState({settingsdlg:false}); };
        if (open) dialogs.push ((<SettingsDialog key="settings_dialog" open={open} nexus={nex} onCancel={onCancel} settingsRef={this.settingsRef} />));

        // The mobile version of React doesn't support stacked Modal dialogs correctly - on android it usually works, but on iOS only the first one is displayed.
        // This is not what we want, and so we restrict display to the top-most dialog (last in queue) here.
        // Doing so leads to the upper dialogs being unmounted, but that should be fine.
        if (mobile && dialogs.length) dialogs = dialogs[dialogs.length - 1];
        
        return dialogs;
    }

    renderMainPanel() {
        let nex = this.props.nexus;
        let manager = this.props.manager;
        let gmcp = this.props.datahandler.GMCP;

        let footer = 37 * parseInt(nex.settings().calc_fontsize()) / 13;
        let gid = nex.active_game_id();
        let game_panel = (<GamePanel key="box_1" style={{flexGrow: 1, position: 'relative', height:'calc(100vh - '+footer+'px)', display: 'flex'}} onMenuClick={(id)=>nex.ui().onMenuClick(id)} gameid={gid} nexus={nex} gmcp={gmcp} channels={gmcp.ChannelList} manager={manager} onModelChange={(model)=>manager.set_model(model)} model={manager.model()} />);
        
        return game_panel;
    }

    renderStatus() {
        let nex = this.props.nexus;
        let manager = this.props.manager;
        let gmcp = this.props.datahandler.GMCP;
        let gi = nex.gameinfo();
        let tips = nex.platform().tooltips_shown();
        let fullscreen = nex.platform().is_fullscreen();
        let status = (<StatusBar key="statusbar" gmcp={gmcp} platform={nex.platform()} settings={nex.settings()} log={nex.log()} on_feature={(feat) => manager.statusbar_feature(feat)} onMenuClick={(id)=>nex.ui().onMenuClick(id)} fullscreen={fullscreen} gameinfo={gi} tooltips={tips} dh={nex.datahandler()} onVolumeChange={(v)=>nex.ui().sounds().set_volume(v)} />);
        return status;
    }

    render() {
        let nex = this.props.nexus;

        let dialogs = this.renderDialogs();
        let game_panel = this.renderMainPanel();
        let status = this.renderStatus();

        let fontsize = nex.settings().calc_fontsize();
        let mainClass = 'box_main';
        let main_panel = (<LayoutVBox mainPanel={true} className={mainClass} key="box_main" style={{width:'100vw', height:'100vh', fontSize:fontsize}}>{[game_panel, status]}{dialogs}</LayoutVBox>);
        return main_panel;
    }
}


export class LayoutManager {
    constructor(nexus) {
        this.layout_version = 3;   // This needs to be changed when an incompatible layout change is made.
        this._nexus = nexus;
        this._disabled_central = [];
        this._channels = [];
        this._floaters = [];
        this._customTabs = {};
        this.wants_characters = false;
        this.wants_intro = false;

        this.game_changed();
    }

    use_default_tabs() {
        let t = this;
        this.set_default_model();
        this._tab_locations = {};
        for (var key in this._default_tabs) {
            var elist = this._default_tabs[key];
            var list = [];
            for (var idx = 0; idx < elist.length; ++idx)
                list.push (elist[idx]);
            this._tab_locations[key] = list;
        }
        // channels
        for (let i = 0; i < this._channels.length; ++i) {
            let tabid = this._channels[i].tabid;
            this._tab_locations[tabid] = this.default_tab_location (tabid);
        }
        this._floaters = [];  // remove floaters
        
        let nex = this._nexus;
        nex.platform().set_timeout(function () {
            // these need to delay to ensure that the old layout doesn't come back
            // custom tabs
            let loc = t.default_tab_location('all_comm');
            let tlist = Object.keys(t._customTabs);
            for (let i = 0; i < tlist.length; ++i) {
                let n = tlist[i];
                t.deactivate_tab(n);   // needed for the tabs to survive a layout reset
                t.activate_tab(n);
                t.set_tab_location(n, loc);
            }
        }, 100);

    }

    // returns the layout as a react object
    get_layout() {
        return (<NexusLayout manager={this} nexus={this._nexus} datahandler={this._nexus.datahandler()} />);
    }

    game_changed() {
        let gi = this._nexus.gameinfo();
        this._customTabs = {};
        this._default_tabs = gi ? gi.default_tabs() : {};
        this.use_default_tabs();
        this.relayout();
    }

    backgroundImage() {
        let gi = this._nexus.gameinfo();
        if (gi.backgroundImage) return gi.backgroundImage();
        return '/images/login/login-background.jpg';
    }
    
    relayout() {
        if (!this.layout) return;
        this.layout.setState ({gmcp: this._nexus.datahandler().GMCP});
    }

    gmcp_updated() {
        this.relayout();
    }

    set_overhead_map(map) {
        let gmcp = this._nexus.datahandler().GMCP;
        gmcp.overheadMap = map;
        this.layout.setState ({ohmap: map});  // updates the layout
    }


    // used by the web version only
    confirm(title, content, onConfirm) {
        this.confirmTitle = title;
        this.confirmText = content;
        this.confirmCallback = onConfirm;
        this.layout.setState({confirmdlg:true});
    }

    // used by the web version only
    alert(title, content, onConfirm) {
        if (!this.layout) {
            // Something failed before the layout has been set up
            console.log(title);
            console.log(content);
            return;
        }

        // Check if we have an other alert already. If we do, abort and run the callback directly.
        if (this.layout.state.alertdlg) {
            if (onConfirm) onConfirm();
            return;
        }

        this.alertTitle = title;
        this.alertText = content;
        this.alertCallback = onConfirm;
        this.layout.setState({alertdlg:true});
    }

    getText(title, text, defaultText, label, okText, onConfirm) {
        this.inputTitle = title;
        this.inputText = text;
        this.inputDefaultText = defaultText;
        this.inputLabel = label;
        this.inputCallback = onConfirm;
        this.inputOkText = okText;
        this.layout.setState({inputdlg:true});
    }

    getPassword(title, text, remember, defaultPassword, okText, onConfirm) {
        this.passwordTitle = title;
        this.passwordText = text;
        this.passwordRemember = remember;
        this.passwordDefault = defaultPassword;
        this.passwordCallback = onConfirm;
        this.passwordOkText = okText;
        this.layout.setState({passworddlg:true});
    }

    showGameAdd(title, server, port, isUpdate, onConfirm) {
        this.gameaddTitle = title;
        this.gameaddServer = server;
        this.gameaddPort = port;
        this.gameaddUpdate = isUpdate;
        this.gameaddCallback = onConfirm;
        this.layout.setState({gameadddlg:true});
    }

    onEditorSubmit(text) {
        text = text.replace(/\t/g, "        ");
        let t = this;
        let nex = t._nexus;
        nex.datahandler().send_GMCP("IRE.Composer.SetBuffer", text);
        nex.platform().set_timeout(function () {
            nex.send_commands("*echo", true);
            nex.send_commands("*save", true);
            nex.platform().focus_input();
        }, 100);
    }

    onEditorCancel() {
        let t = this;
        let nex = t._nexus;
        nex.send_commands("*quit", true);
        nex.send_commands("no", true);
        nex.platform().focus_input();
    }

    open_editor(text, title) {
        this.editorTitle = title;
        this.editorText = text;
        this.layout.setState({editordlg:true});
    }

    open_popup(id, commands, show_noshow, image, content) {
        let t = this;
        let nex = t._nexus;

        let popupProps = { id:id, commands:commands, noshow:show_noshow, content:content, image:image };
        popupProps.onDone = () => { nex.send_commands('done', true); };
        popupProps.onContinue = () => { nex.send_commands('continue', true); };
        popupProps.onBack = () => { nex.send_commands('back', true); };
        popupProps.handleNoShow = () => { nex.datahandler().gmcp_popup_dontshow(id); };
        let st = { popupdlg:true, popupProps: popupProps };
        this.layout.setState(st);
    }
    
    hide_popup() {
        this.layout.setState({popupdlg:false});
    }

    show_text_window(caption, lines) {
        let wnd = {};
        
        wnd.open = true;
        wnd.id = 0;
        wnd.header = caption;
        wnd.lines = lines;
        this.layout.openTextWindow(wnd);
    }

    help_window(lines) {
        if (!lines.length) return;
        let heading = lines.shift();  // The first line is the heading - all the games should be sending it correctly. Hopefully.
        heading = heading.parsed_line ? heading.parsed_line.text() : '';
        for (let i = 0; i < lines.length; ++i) lines[i].monospace = true;
        
        this.show_text_window(heading, lines);
    }

    command_window(lines, command) {
        this.show_text_window('Command: ' + command, lines);
    }

    text_window(header, lines) {
        let t = this;
        let nex = t._nexus;
        let parser = new LineParser (nex.settings(), nex.datahandler());
        let parsed = [];
        for (let idx = 0; idx < lines.length; ++idx) {
            let l = {};
            l.parsed_line = parser.parse_line(lines[idx]);
            parsed.push (l);
        }

        this.show_text_window(header, parsed);
    }

    show_login(acctemail, acctpass, show_register=false) {
        this.close_dialogs();
        this.layout.setState({ logindlg:true, loginregister:show_register, loginemail:acctemail, loginpass:acctpass, onlogin: (email, pass, remember) => this.on_login (email, pass, remember), onregister: (email, pass, pass2) => this.on_acct_create (email, pass, pass2), onforgot: (email) => this.on_acct_forgot(email) });
    }

    hide_login() {
        this.layout.setState({ logindlg:false });
    }

    show_register() {
        this.show_login('', '', true);
    }

    show_characters() {
        this.close_dialogs();
        if (this.layout) {
            this.layout.setState({charactersdlg:true, ongamelogin: (name, load) => this.on_game_login (name, load), onlogout: () => this.on_logout(), onchars_login: () => this.on_show_login(), onchars_register: () => this.on_show_register(), onaddchar: (chars, cb) => this.on_add_char(chars, cb), onrenamechar: (chars, cid, charname, cb) => this.on_rename_char(chars, cid, charname, cb), oncreation: () => this.show_creation(), onacctchange: (email, pass, pass2) => this.on_acct_change(email, pass, pass2), onacctremove: () => this.on_acct_remove() });
        } else {
            this.wants_characters = true;
        }
    }

    show_disconnect_screen(name, reason, onDownload) {
        this.close_dialogs();
        this.disconnectReason = reason;
        this.onReconnect = () => this.on_game_login(name, true);
        this.onDownload = onDownload;
        this.onCharacters = () => this.show_characters();
        this.layout.setState({disconnecteddlg:true});
    }

    on_show_register() {
        this.show_register();
    }
    
    on_show_login() {
        this.show_login('', '');
    }

    show_creation() {
        let gi = this._nexus.gameinfo();
        if (!gi.is_ire_game()) {
            this._nexus.platform().alert('Error', 'Creation is only supported on Iron Realms games.');
            return;
        }

        this.close_dialogs();
        this.layout.setState({creationdlg:true});
    }

    toggle_settings() {
        this.show_settings(!this.layout.state.settingsdlg);
    }

    settings_shown() {
        return this.layout.state.settingsdlg;
    }

    update_settings() {
        if (!this.layout) return;
        if (!this.layout.state.settingsdlg) return;
        this.layout.setState({settingsdlg:true});   // this re-renders
    }

    show_settings(show) {
        if (show) this.close_dialogs();
        this.layout.setState({settingsdlg:show});
    }

    count_dialogs() {
        let t = this;
        let res = 0;
        if (!t.layout) return 0;
        let st = t.layout.state;
        if (st.confirmdlg) res++;
        if (st.alertdlg) res++;
        if (st.logindlg) res++;
        if (st.charactersdlg) res++;
        if (st.popupdlg) res++;
        if (st.creationdlg) res++;
        if (st.inputdlg) res++;
        if (st.passworddlg) res++;
        if (st.gameseldlg) res++;
        if (st.settingsdlg) res++;
        if (st.gameadddlg) res++;
        if (st.editordlg) res++;
        if (st.disconnecteddlg) res++;
        if (st.textwindows.length) res += st.textwindows.length;
        return res;
    }

    close_dialogs() {
        if (!this.layout) return;
        this.layout.setState({confirmdlg: false, alertdlg:false, logindlg: false, charactersdlg: false, disconnecteddlg:false, popupdlg:false, creationdlg:false, inputdlg:false, passworddlg:false, gameseldlg:false, settingsdlg:false, gameadddlg:false, editordlg:false, textwindows:[], introdlg:false});
    }

    close_escapable_dialogs() {
        if (!this.layout) return false;
        if (this.layout.state.alertdlg) {
            this.layout.setState({alertdlg:false});
            return true;
        }
        if (this.layout.state.popuplg) {
            this.layout.setState({popupdlg:false});
            return true;
        }
        if (this.layout.state.textwindows && this.layout.state.textwindows.length) {
            let wnds = this.layout.state.textwindows;
            this.layout.closeTextWindow(wnds[wnds.length-1].id);
            return true;
        }
        return false;
    }

    command_from_tab(cmd, noexpand=false, to_input=false) {
        try {
            if (to_input)
                this._nexus.platform().set_input(cmd);
            else
                this._nexus.send_commands(cmd, noexpand);
            // On desktop, focus the input. On mobile, don't - it opens the keyboard.
            if (!this._nexus.platform().real_mobile())
                this._nexus.platform().focus_input();
        }
        catch(e) {
            // Something went wrong - we need to let the player know.
            // What complicates the issue is that the most likely cause is some script trying to override a core function and failing to do so properly.
            // So we cannot simply use display_notice, because that may be faulty, too. Hence, trying this one - scripts typically won't override that.
            this._nexus.platform().alert('Alert', 'An error occurred while sending the command. Typically, this happens because of an incompatible or faulty script that attempts to override core Nexus functionality. Please disable any such scripts, then try again.');
        }
    }

    script_from_tab(script, name) {
        this._nexus.exec_script(script, undefined, undefined, name);
    }

    show_intro() {
        if (this.layout)
            this.layout.setState({introdlg:true,charactersdlg:false});
        else
            this.wants_intro = true;
    }

    hide_intro() {
        this.layout.setState({introdlg:false});
    }
    
    on_intro_close() {
        this.hide_intro();
        this.show_characters();
    }

    on_intro_game_select(gid) {
        let nex = this._nexus;
        this.hide_intro();
        nex.set_current_game(gid, null, true);
        this.show_creation();
    }
    
    on_login(name, pass, remember) {
        let nex = this._nexus;
        if (!name) { nex.platform().alert('Error', 'Please provide your e-mail.'); return; }
        if (!pass) { nex.platform().alert('Error', 'Please provide the account password.'); return; }
        let chars = nex.serverapi();
        this.logging_in = true;
        if (this.layout) this.layout.setState({logging_in:true});
        chars.login(name, pass).then(() => {
            if (this.layout) this.layout.setState({logging_in:false});
            this.logging_in = false;
            if (remember)
                nex.store_login(name, pass);
            nex.platform().set_local_setting("IRE.char_name", name);
            nex.ui().show_characters();
        })
        .catch((error) => {
            this.logging_in = false;
            this.layout.setState({logging_in:false});
            nex.platform().alert('Error', String(error));
        });
    }

    on_acct_create(email, pass, pass2) {
        let nex = this._nexus;
        if (!email) { nex.platform().alert('Error', 'Please provide your e-mail.'); return; }
        if (!pass) { nex.platform().alert('Error', 'Please provide the account password.'); return; }
        if (!pass2) { nex.platform().alert('Error', 'Please confirm the account password.'); return; }
        if (pass !== pass2) { nex.platform().alert('Error', 'The passwords do not match.'); return; }
        
        let chars = nex.serverapi();
        chars.add_account(email, pass).then(() => {
            nex.platform().alert('Success', 'Your account has been created. You can now log in. Welcome to Nexus!');
            this.show_login(email, pass);
        })
        .catch((error) => {
            nex.platform().alert('Error', String(error));
        });
    }

    on_acct_change(email, pass, pass2) {
        let nex = this._nexus;
        let chars = nex.serverapi();
        if (pass !== pass2) { nex.platform().alert('Error', 'The passwords do not match.'); return; }
        if (((!email) || (email === chars.email())) && (!pass)) { nex.platform().alert('Error', 'You did not enter anything to change.'); return; }

        let act = email ? 'Change Email' : 'Change Password';
        this.getPassword(act, 'Please enter your CURRENT account password to proceed:', false, '', act, (origpass, remember) => {
            let chars = nex.serverapi();
            chars.change_account(origpass, email, pass).then(() => {
                if (email) chars.set_email(email);
                nex.platform().alert('Success', 'Your account has been updated.', () => { this.show_characters() });
            })
            .catch((error) => {
                nex.platform().alert('Error', String(error), () => { this.show_characters() });
            });
        });
    }

    on_acct_remove() {
        let nex = this._nexus;
        this.getPassword('Delete Nexus Account', 'Please enter your CURRENT account password. Once you do that and click the Delete button, YOUR ACCOUNT AND ALL ASSOCIATED SETTNIGS WILL BE DELETED.', false, '', 'DELETE NEXUS ACCOUNT', (origpass, remember) => {
            let chars = nex.serverapi();
            chars.delete_account(origpass).then(() => {
                nex.platform().alert('Success', 'Your account has been deleted.');
                this.on_logout();
            })
            .catch((error) => {
                nex.platform().alert('Error', String(error), () => { this.show_characters() });
            });
        });
    }

    on_acct_forgot(email) {
        let nex = this._nexus;
        if (!email) { nex.platform().alert('Error', 'Please provide the account e-mail.'); return; }
        
        let chars = nex.serverapi();
        chars.forgot_password(email).then(() => {
            nex.platform().alert('Instructions Sent', 'If the supplied e-mail is registered with Nexus, instructions will be sent to it shortly.', () => {
                this.hide_login();
                this.show_characters();
            });
        })
        .catch((error) => {
            nex.platform().alert('Error', String(error));
        });
    }

    login_with_password(name, pass, load) {
        let nex = this._nexus;
        let gi = nex.gameinfo();
        let prompt = 'Please enter the password for ' + Util.ucfirst(name) + ' on ' + Util.ucfirst(gi.game_short()) + ':';
        if (nex.platform().real_mobile()) prompt = null;
        this.getPassword(Util.ucfirst(name) + ' on ' + Util.ucfirst(gi.game_short()), prompt, true, pass, 'Login', (pass, remember) => {
            this._nexus.login (name, pass, load, remember);
        });
    }

    on_game_login(name, load) {
        let nex = this._nexus;
        let gi = nex.gameinfo();

        // Our game? need the password
        if (gi.is_ire_game()) {
            // Let's see if we have one stored.
            this._nexus.get_game_password(nex.active_game_id(), name).then((pass) => {
                this.login_with_password(name, pass, load);
            }).catch(() => { this.login_with_password(name, '', load) });
        } else {
            // The name won't actually be sent anywhere
            this._nexus.login (name, null, load, false);
        }
    }

    on_add_char(chars, on_success) {
        let nex = this._nexus;
        let gi = nex.gameinfo();
        let gname = gi.game_name();
        let gid = nex.active_game_id();
        this.getText('Add Existing Character', 'Enter the name of your existing character on ' + Util.ucfirst(gname) + ':', '', 'Character Name', 'Continue', (name) => {
            let func = (pass, remember) => {
                chars.char_add(name, gid, pass).then(()=>{ 
                    nex.store_game_password(gid, name, pass);
                    on_success();
                })
                .catch((err) => nex.platform().alert('Error adding character', String(err)));
            };
            if (gi.is_ire_game())
                this.getPassword('Enter Password To Confirm', 'Please enter the password for ' + Util.ucfirst(name) + ' on ' + Util.ucfirst(gname) + ':', true, '', 'Add Character', func);        
            else
                func('');
        });
    }

    on_rename_char(chars, cid, charname, on_success) {
        let nex = this._nexus;
        let gi = nex.gameinfo();
        let gname = gi.game_name();
        let gid = nex.active_game_id();
        let func = (name) => {
            chars.char_change(cid, name, gid, '').then(()=>{ on_success(); })
            .catch((err) => nex.platform().alert('Error renaming character', String(err)));
        };
        this.getText('Rename Character', 'Edit the name of your existing character on ' + Util.ucfirst(gname) + ':', charname, 'Character Name', 'Rename Character', func);
    }

    on_logout() {
        let nex = this._nexus;
        nex.serverapi().logout();
        
        // lose the stored password, if any
        let pwds = nex.passwords();
        pwds.clear(null, null, 'name');
        pwds.clear(null, null, 'password');

        this.show_characters();
    }

    statusbar_feature(feature) {
        this._nexus.ui().statusbar_feature(feature);
    }

    fullscreenChange(fs) {
        this.layout.setState({'fullscreen':fs});
    }

    buffer_for_tab(name) {
        if (name === 'output_main') return this._nexus.ui().buffer();
        if (name === 'all_comm') return this._nexus.ui().comm_buffer();
        if (name.substr(0, 8) === 'channel_') {
            let rec = this.channel_record(name);
            if (rec) return rec.buffer;
        }
        return null;
    }

    // tab as a react object
    get_tab_object(name, gmcp) {
        let t = this;
        let tips = this._nexus.platform().tooltips_shown();

        if (name === 'affdef') {
            return (<TabAffDef gmcp={gmcp} gameinfo={t._nexus.gameinfo()} datahandler={t._nexus.datahandler()} tooltips={tips} />);
        }
        if (name === 'room') {
            return (<TabItemList type="room" title="Room" gmcp={gmcp} datahandler={t._nexus.datahandler()} fetchItems={(id) => this._nexus.datahandler().send_GMCP("Char.Items.Contents", id)} settings={t._nexus.settings()} oncommand = {(cmd) => t.command_from_tab(cmd,true)} />);
        }
            
        if (name === 'inventory') {
            return (<TabItemList type="inv" title="Inventory" gmcp={gmcp} datahandler={t._nexus.datahandler()} fetchItems={(id) => this._nexus.datahandler().send_GMCP("Char.Items.Contents", id)} settings={t._nexus.settings()} oncommand = {(cmd) => t.command_from_tab(cmd,true)} />);
        }
        if (name === 'map') {
            return (<TabMap gmcp={gmcp} nexus={this._nexus} platform={this._nexus.platform()} ohmap={gmcp.overheadMap} datahandler={t._nexus.datahandler()} gameinfo={t._nexus.gameinfo()} settings={t._nexus.settings()} oncommand = {(cmd) => t.command_from_tab(cmd,true)} />);
        }
        if (name === 'skills') {
            let gfetcher = ((name) => t._nexus.datahandler().request_skill (name));
            let sfetcher = ((gname, sname) => t._nexus.datahandler().request_skill (gname, sname));
            return (<TabSkills gmcp={gmcp} ongroupfetch={ gfetcher } onskillfetch={ sfetcher } />);
        }
        if (name === 'news') {
            let gfetcher = ((section, fromID, toID) => t._nexus.datahandler().request_news_section (section, fromID, toID));
            let efetcher = ((section,id) => t._nexus.datahandler().request_news (section, id));
            return (<TabNews gmcp={gmcp} onsectionfetch={ gfetcher } onentryfetch={ efetcher } />);
        }
        if (name === 'who') {
            return (<TabWho style={{overflowX : 'auto'}}  gmcp={gmcp} sett={t._nexus.settings()} onclick={ (name) => t.command_from_tab ('honours '+name, true) } />);
        }
        if ((name === 'tasks') || (name === 'quests') || (name === 'achievements')) {
            return (<TabTasks gmcp={gmcp} tasktype={name} />);
        }
        if (name === 'gauges') {
            let nex = this._nexus;
            let gi = nex.gameinfo();
            let alter_gauge_var = gi.alter_gauge_var ? gi.alter_gauge_var : null;
            let gauges = nex.ui().gauges().get_gauges();
            return (<TabGauges gmcp={gmcp} alter_gauge_var={alter_gauge_var} nexus={nex} gauges={gauges} />);
        }
        if (name === 'buttons') {
            let nex = this._nexus;
            let buttons = nex.ui().buttons();
            return (<TabButtons gmcp={gmcp} nexus={nex} buttons={buttons} oncommand = {(cmd, noexpand) => t.command_from_tab(cmd, noexpand)} onscript = {(script, name) => t.script_from_tab(script, name)} onnotice = {(txt,c) => nex.display_notice (txt,c)} />);

        }
        if (name === 'bottom') {
            // This one is not a tab, but we may as well keep them together.
            let nex = this._nexus;
            let mobile = nex.platform().real_mobile();
            let gi = nex.gameinfo();
            let alter_gauge_var = gi.alter_gauge_var ? gi.alter_gauge_var : null;
            let bottom = (<LayoutBottom
                key="bottom"
                style={{maxHeight: '500px'}}
                gmcp={gmcp}
                settings={nex.settings()}
                nexus={nex}
                lbuffer={nex.ui().buffer()}
                gauges={nex.ui().gauges().get_gauges()}
                buttons={nex.ui().buttons()}
                balances={gi.balances()}
                tooltips={nex.platform().tooltips_shown()}
                singleline={mobile}
                alter_gauge_var={alter_gauge_var}
                dh={nex.datahandler()}
                manager={t}
                oncommand = {(cmd, noexpand) => t.command_from_tab(cmd, noexpand)}
                onscript = {(script, name) => t.script_from_tab(script, name)}
                onnotice = {(txt,c) => nex.display_notice (txt,c)}
                on_feature={(feat) => t.statusbar_feature(feat)}
                onVolumeChange={(v)=>nex.ui().sounds().set_volume(v)} 
            />);
            return bottom;
        }
        if (name === 'center') {
            let output = this.get_tab_object('output_main', gmcp);
            let bottom = this.get_tab_object('bottom', gmcp);
            let res = (<LayoutCenter className='box_center' main={output} bottom={bottom} />);
            return res;
        }

        let buffer = this.buffer_for_tab(name);
        if (buffer) {
            if (name === 'output_main') {
                return (<TabOutput buffer={buffer} nexus={t._nexus} gmcp={gmcp} settings={t._nexus.settings()} oncommand = {(cmd) => t.command_from_tab (cmd, true) } />);
            }
            if (name === 'all_comm') {
                return (<TabOutput buffer={buffer} nexus={t._nexus} settings={t._nexus.settings()} channel="all" oncommand = {(cmd) => t.command_from_tab (cmd, true) } />);
            }
            if (name.substr(0, 8) === 'channel_') {
                let rec = t.channel_record(name);
                if (!rec) return null;
                
                return (<TabOutput buffer={buffer} nexus={t._nexus} settings={t._nexus.settings()} channel={rec.channel} oncommand = {(cmd, isprompt) => t.command_from_tab (cmd, true, isprompt) } />);
            }
        }
        
        if (this._customTabs[name]) {
            let res = this._customTabs[name];
            if (typeof res === 'string')
                res = (<TabCustom tabname={name} contents={res} />);
            return res;
        }
    }

    custom_tabs() {
        return this._customTabs;
    }

    register_custom_tab(name, component=null) {
        let obj = this.get_tab_object(name);
        if (obj) return;
        if (component === null) this._customTabs[name] = '';
        else this._customTabs[name] = component;
        var loc = this.default_tab_location('all_comm');
        this.set_tab_location(name, loc, null);
        this.layout.setState({'update':1});
    }

    rename_custom_tab(name, newname) {
        let content = this._customTabs[name];
        if (content === undefined) return;
        let obj = this.get_tab_object(newname);
        if (obj) return;
        let loc = this.tab_location(name);
        this._customTabs[newname] = content;
        delete this._customTabs[name];
        if (!loc) loc = this.default_tab_location('all_comm');
        this.set_tab_location(name, null);   // remove the old tab
        this.set_tab_location(newname, loc, null);
        this.layout.setState({'update':1});
    }

    unregister_custom_tab(name) {
        let content = this._customTabs[name];
        if (content === undefined) return;

        this.deactivate_tab(name);
        delete this._customTabs[name];
        this.layout.setState({'update':1});
    }

    set_custom_tab_html(name, html) {
        let content = this._customTabs[name];
        if (typeof content !== 'string') {
            this._nexus.display_notice ('A script attempted to write to a custom tab "' + name + '", which is not properly registered.');
            return;
        }
        this._customTabs[name] = html;
        this.layout.setState({'update':1});  // TODO do this better somehow? We only need to update that one tab ...
    }

    append_custom_tab_html(name, html) {
        let content = this._customTabs[name];
        if (typeof content !== 'string') {
            this._nexus.display_notice ('A script attempted to write to a custom tab "' + name + '", which is not properly registered.');
            return;
        }
        this._customTabs[name] += html;
        this.layout.setState({'update':1});  // TODO do this better somehow? We only need to update that one tab ...
    }

    tab_object_is_output(name) {
        if (name === 'output_main') return true;
        if (name === 'all_comm') return true;
        if (name.substr(0, 8) === 'channel_') return true;
        return false;
    }

    get_tab_caption(name) {
        if (name === 'affdef') return 'You';
        if (name === 'output_main') return 'Main';
        if (name === 'all_comm') return 'All Comm';
        if (name.substr(0, 8) === 'channel_') return 'Channel: ' + Util.ucfirst(name.substr(8)).replace('_', ' ');
        return Util.ucfirst (name);
    }

    get_tab_tooltip(name) {
        if (name === 'affdef') return 'Your Character';
        if (name === 'room') return 'Room Info';
        if (name === 'who') return 'Who is Online';
        if (name === 'output_main') return 'Game Messages and Information';
        if (name === 'all_comm') return 'Global Communication Window';
        if (name.substr(0, 8) === 'channel_') return 'Channel: ' + Util.ucfirst(name.substr(8)).replace('_', ' ');
        return Util.ucfirst (name);
    }


    // font-awesome icon
    get_tab_icon(name) {
        if (name === 'affdef') return 'street-view';
        if (name === 'room') return 'location-dot';
        if (name === 'inventory') return 'book-sparkles';
        if (name === 'map') return 'route';
        if (name === 'skills') return 'flask-round-potion';
        if (name === 'news') return 'newspaper';
        if (name === 'who') return 'user-group-crown';
        if ((name === 'tasks') || (name === 'achievements')) return 'square-question';
        if (name === 'quests') return 'square-exclamation';
        if (name === 'gauges') return 'gauge';
        if (name === 'buttons') return 'keyboard';

        if (name === 'output_main') return 'message-lines';
        if (name === 'all_comm') return 'message-lines';
        if (name.substr(0, 8) === 'channel_') return 'message-lines';

        return 'file';
    }

    get_tab_icon_color(name) {
        if (name === 'affdef') return '#ffffff';
        if (name === 'room') return '#ffffff';
        if (name === 'inventory') return '#e8ad59';
        if (name === 'map') return '#6cd578';
        if (name === 'skills') return '#9a44ab';
        if (name === 'news') return '#707070';
        if (name === 'who') return '#ffffff';
        if ((name === 'tasks') || (name === 'achievements')) return '#ffffff';
        if (name === 'quests') return '#000000';

        if (name === 'output_main') return null;
        if (name === 'all_comm') return null;
        if (name.substr(0, 8) === 'channel_') return null;

        return null;
    }

    get_tab_icon_color_2(name) {
        if (name === 'affdef') return '#35ac49';
        if (name === 'room') return '#44b4ca';
        if (name === 'inventory') return '#ffffff';
        if (name === 'map') return '#ffffff';
        if (name === 'skills') return '#ffffff';
        if (name === 'news') return '#ffffff';
        if (name === 'who') return '#8d9fbf';
        if ((name === 'tasks') || (name === 'achievements')) return '#a7a2e6';
        if (name === 'quests') return '#ffff80';

        if (name === 'output_main') return null;
        if (name === 'all_comm') return null;
        if (name.substr(0, 8) === 'channel_') return null;

        return null;
    }

    get_tab_click_handler(name) {
        return undefined;
    }

    encode() {
        var res = {};
        res['layout'] = this._model;
        res['layout_version'] = this.layout_version;
        res['floaters'] = this.encode_floaters();
        res['tabs'] = this.encode_tabs();
        res['channels'] = this.encode_channels();
        res['disabled_central'] = this._disabled_central;
        res['custom_tabs'] = this._customTabs;
        return res;
    }

    apply(data) {
        let layoutVersion = parseInt(data['layout_version']);
        if (isNaN(layoutVersion)) layoutVersion = 0;
        if (layoutVersion < this.layout_version) {
            this.use_default_tabs();
            return;
        }
//        this._customTabs = data['custom_tabs'];
//        if (!this._customTabs) this._customTabs = {};
        let custom = data['custom_tabs'];
        for (let tabname in custom) {
            if (!custom.hasOwnProperty(tabname)) continue;
            this.register_custom_tab(tabname, null);
        }
        
        this._model = data['layout'];
        this.apply_floaters (data['floaters']);
        // this needs to be called after apply_floaters, as it relies on the floaters being restored
        this.apply_tabs (data['tabs']);
        this.apply_channels (data['channels']);
        this._disabled_central = data['disabled_central'];
    }

    get_floater(id) {
        for (let idx = 0; idx < this._floaters.length; ++idx) {
            if (!this._floaters[idx]) continue;
            if (this._floaters[idx]['num'] === id) return this._floaters[idx];
        }
        return null;
    }

    set_floater_data(id, x, y) {
        let f = this.get_floater (id);
        if (!f) return;
        f['x'] = x;
        f['y'] = y;
        this.relayout();   // may be inefficient to do this every time, but let's see ...
    }

    new_floater() {
        let num = 1;
        while (this.get_floater(num)) num++;
        let e = {};
        e['num'] = num;
        e['x'] = 250;   // TODO maybe some better numbers here?
        e['y'] = 250;
        this._floaters.push (e);
        return e;
    }

    remove_floater(id) {
        for (let idx = 0; idx < this._floaters.length; ++idx) {
            if (!this._floaters[idx]) continue;
            if (this._floaters[idx]['num'] !== id) continue;
            this._floaters.splice(idx, 1);
            break;
        }
    }

    encode_floaters() {
        let res = [];
        for (let idx = 0; idx < this._floaters.length; ++idx) {
            if (!this._floaters[idx]) continue;
            let e = {};
            e['num'] = this._floaters[idx]['num'];
            e['x'] = this._floaters[idx]['x'];
            e['y'] = this._floaters[idx]['y'];
            res.push(e);
        }
        return res;
    }
    
    apply_floaters(data) {
        this._floaters = [];
        for (let idx = 0; idx < data.length; ++idx) {
            if (!data[idx]) continue;
            let e = {};
            e['num'] = data[idx]['num'];
            e['x'] = data[idx]['x'];
            e['y'] = data[idx]['y'];
            this._floaters.push(e);
        }
    }

    apply_settings(s) {
        let limit = this._nexus.platform().real_mobile() ? s.scrollback_msg_limit : 0;
        for (let i = 0; i < this._channels.length; ++i) {
            this._channels[i].buffer.set_line_limit(limit);
        }        
    }


    // *** LAYOUT MODEL (desktop only)
    
    model() {
        if (!this._model) return this.default_model();
        return this._model;
    }

    set_model(model) {
        this._model = model;
    }

    model_for_tab(tabid) {
        let item = {};
        item.id = tabid;
        item.type = 'tab';
        item.component = tabid;
        item.name = this.get_tab_caption (tabid);
        item.icon = this.get_tab_icon (tabid);
        item.helpText = this.get_tab_tooltip (tabid);
        return item;
    }

    model_for_tabs(loc) {
        let lst = this._default_tabs[loc];
        if (!lst) return null;
        let res = {};
        res.type = 'tabset';
        res.weight = 50;
        res.id = loc;

        let tabs = [];
        for (let idx = 0; idx < lst.length; ++idx) {
            let tab = lst[idx];
            let m = this.model_for_tab(tab);
            if (m) tabs.push (m);
        }
        res.children = tabs;
        return res;
    }

    model_for_item(id) {
        let item = { type: 'tab', component: id };
        return { type: 'tabset', enableTabStrip: false, enableRename: false, enableDrop: false, children: [ item ] };
    }

    __find_tabset_in_model_recurs(loc, e) {
        if (e.id === loc) return e;
        let childs = e.children;
        if (!childs) return undefined;
        for (let idx = 0; idx < childs.length; ++idx) {
            let r = this.__find_tabset_in_model_recurs(loc, childs[idx]);
            if (r) return r;
        }
        return undefined;
    }

    find_tabset_in_model(loc) {
        return this.__find_tabset_in_model_recurs(loc, this.model().layout);
    }

    restore_loc_in_model(loc, tab_to_add=undefined) {
        let model = this._model;
        if (!model) return false;
        // is it already there?
        let set = this.find_tabset_in_model(loc);
        if (set) return false;

        // if not, we need to re-add and redraw
        let locmodel = this.model_for_tabs(loc);
        if (!locmodel) return false;
        locmodel.children = [];
        if (tab_to_add) locmodel.children.push(tab_to_add);
        // goes to the left or right panel
        let isLeft = ((loc == 'container_1') || (loc == 'container_2'))
        let panel = this.find_tabset_in_model(isLeft ? 'left' : 'right');
        if (!panel) {
            // No panel? Let's add it back.
            let center = this.find_tabset_in_model('main');
            if (isLeft) {
                panel = this.model_left_column([]);
                center.children.unshift (panel);
            } else {
                panel = this.model_right_column([]);
                center.children.push (panel);
            }
        }
        // and add it
        if ((loc == 'container_1') || (loc == 'container_3')) {
            panel.children.unshift (locmodel);
        } else {
            panel.children.push (locmodel);
        }

        console.log(model);
        this.on_tabs_changed();
        return true;
    }

    model_left_column(children) {
        return { id: 'left', type: 'column', weight: 20, children: children };
    }

    model_right_column(children) {
        return { id: 'right', type: 'column', weight: 20, children: children };
    }

    default_model() {
        let models = [];
        for (let idx = 1; idx <= 4; ++idx)
            models[idx] = this.model_for_tabs('container_'+idx);

        let center = this.model_for_item('center');
        center.weight = 100;
        let main = { id: 'center', type: 'column', weight: 60, children: [ center ] };

        // left = container_1 + container_2
        let children = [];
        if (models[1]) children.push(models[1]);
        if (models[2]) children.push(models[2]);
        let left = children.length ? this.model_left_column(children) : null;

        // right = container_3 + container_4
        children = [];
        if (models[3]) children.push(models[3]);
        if (models[4]) children.push(models[4]);
        let right = children.length ? this.model_right_column(children) : null;

        children = [];
        if (left) children.push (left);
        children.push (main);
        if (right) children.push (right);

        // result = left + main + right
        let layout = { id: 'main', type: 'row', weight: 100, children: children };

        let settings = { tabSetEnableMaximize: false, tabEnableClose: false };
        let borders = [];
        return { layout: layout, global: settings, borders: borders };
    }

    set_default_model() {
        delete this._model;
    }

    // *** TABS ***
    
    tabs() {
        return this._tab_locations;
    }

    encode_tabs() {
        return this._tab_locations;
    }

    apply_tabs(data) {
        this._tab_locations = data;
    }

    tab_locations() {
        let res = [];
        for (var key in this._tab_locations) {
            if (key === 'el_version') continue;
            res.push (key);
        }
        return res;
    }

    default_tabs_at_location(loc) {
        let res = this._default_tabs[loc];
        if (!res) res = [];
        return res;
    }

    tabs_at_location(loc) {
        // if the platform doesn't support changing tab locations, use the defaults
        if (!this._nexus.platform().supports_floaters()) return this._default_tabs[loc];
        
        if (!this._tab_locations[loc]) return [];
        return this._tab_locations[loc];
    }

    tab_location(tabid) {
        for (let key in this._tab_locations) {
            if (key === 'el_version') continue;
            if (key === 'disabled') continue;
            let elist = this._tab_locations[key];
            if (elist.indexOf (tabid) >= 0) return key;
        }
        return null;
    }

    tab_location_name(loc) {
        if (loc === 'container_1') return 'Upper left panel';
        if (loc === 'container_2') return 'Lower left panel';
        if (loc === 'container_3') return 'Upper right panel';
        if (loc === 'container_4') return 'Lower right panel';
        if (loc === 'main_container') return 'Central panel';
        if (loc.startsWith('floater_')) return 'Floater ' + loc.substr(8);
        return loc;
    }

    on_tabs_changed() {
        if (!this.layout) return;
        this.layout.setState ({changed:1});
    }

    set_tab_location(tabid, loc, position=null, callevent=true) {

        var curr = this.tab_location (tabid);
        if (curr) {
            let oldpos = this._tab_locations[curr].indexOf (tabid);
            this._tab_locations[curr].splice (oldpos, 1);
        }

        if (this.flexLayout) {
            if (!loc) {
                this.flexLayout.removeTab(tabid);
            }
            else if (curr) {
                this.flexLayout.moveTab(tabid, loc);
            } else {
                // We need to create a new one.
                let entry = this.model_for_tab(tabid);
                this.flexLayout.addTab(entry, loc);
            }
        }

        if (loc) {
            if (!this._tab_locations[loc]) this._tab_locations[loc] = [];
            if ((position != null) && (position >= 0))
                this._tab_locations[loc].splice (position, 0, tabid);
            else
                this._tab_locations[loc].push (tabid);
        }

        // empty floater?
        // This needs to be after the tab is re-inserted, otherwise we crash if we try to insert into the same floater.
        if (curr && curr.startsWith('floater_') && (!this._tab_locations[curr].length)) {
            let fid = parseInt(curr.substr(8));
            this.remove_floater(fid);
            delete this._tab_locations[curr];
        }

        if (callevent) this.on_tabs_changed();
    }

    remove_tab_location(loc) {
        // if there are tabs, move them back to their default positions
        var tabs = this._tab_locations[loc];
        if (tabs && tabs.length) {
            for (var idx = tabs.length - 1; idx >= 0; --idx)  // reverse order, because set_tab_location modifies the list
            {
                var tabid = tabs[idx];
                let def = this.default_tab_location(tabid);
                this.set_tab_location(tabid, def, null, false);
            }
        }
        delete this._tab_locations[loc];
        this.on_tabs_changed();
    }

    default_tab_location(tabid) {
        for (let key in this._default_tabs) {
            if (key === 'el_version') continue;
            let elist = this._default_tabs[key];
            if (elist.indexOf (tabid) >= 0) return key;
        }
        // channel tabs
        if (tabid.substr(0, 8) === 'channel_') return this.default_tab_location('all_comm');

        return null;
    }

    activate_tab(tabid) {
        if (this.tab_is_active(tabid)) return;

        let loc = this.default_tab_location(tabid);
        if (loc === 'disabled') loc = 'container_3';   // disabled tabs start here
        this.set_tab_location(tabid, loc, null);
    }

    deactivate_tab(tabid) {
        if (!this.tab_is_active(tabid)) return;

        this.set_tab_location(tabid, null, null);
    }

    tab_exists(tabid) {
        if (this.default_tab_location(tabid) !== null) return true;
        if (tabid.substr(0, 8) === 'channel_') return this.channel_tabid_exists (tabid);
        return false;
    }

    tab_is_active(tabid) {
        if (this.tab_location(tabid) != null) return true;
        return false;
    }

    unpin_tab(tabid) {
        let loc = this.tab_location(tabid);
        if (loc.startsWith('floater_')) return;  // already not pinned
        // create a new floater, move the tab there
        let f = this.new_floater();
        this.set_tab_location(tabid, 'floater_' + f['num']);
    }
    
    pin_tab(tabid) {
        let loc = this.default_tab_location(tabid);
        this.set_tab_location(tabid, loc);
    }

    // *** TABS - END ***
    
    // *** CHANNEL TABS ***

    channel_id_from_cmd(cmd) {
        if (cmd === 'all') return 'all_comm';
        return 'channel_' + cmd.replace(/\s/g,"_");
    }

    channel_record(tabid) {
        for (let i = 0; i < this._channels.length; ++i) {
            if (this._channels[i].tabid === tabid) return this._channels[i];
        }
        return null;
    }

    encode_channels() {
        let res = [];
        for (let i = 0; i < this._channels.length; ++i) {
            let ch = this._channels[i];
            if (!this.channel_active(ch.channel)) continue;
            res.push ({name: ch.name, caption: ch.caption, command: ch.channel});
        }        
        return res;
    }
    
    apply_channels(data) {
        for (let i = 0; i < data.length; ++i) {
            let ch = data[i].name;
            let caption = data[i].caption;
            let command = data[i].command;
            if (!this.channel_active (ch)) continue;
            
            let tabid = this.channel_id_from_cmd(command);
            let loc = this.tab_location(tabid);
            this.open_channel(ch, caption, command );
            this.set_tab_location(tabid, loc);
        }
    }

    channel_active(channel) {
        return this.tab_is_active (this.channel_id_from_cmd(channel));
    }

    channels_enabled() {
        return true;
    }

    open_channel(name, caption, command) {
        if (!this.channels_enabled()) return false;
        if (this.channel_active (command)) return false;
        
        let tabid = this.channel_id_from_cmd(command);
        let rec = this.channel_record (tabid);
        if (!rec) {
            // need a new record here
            rec = {};
            rec.tabid = tabid;
            rec.name = name;
            rec.channel = command;
            rec.caption = caption;
            rec.newtext = false;
            
            rec.buffer = new LineBuffer();
            rec.buffer.on_lines_changed = function() {
                if (rec.buffer.output) rec.buffer.output.updated();
            };

            this._channels.push (rec);
        }

        this._nexus.datahandler().send_GMCP("Comm.Channel.Enable", name);
        
        var loc = this.default_tab_location('all_comm');
        this.set_tab_location(tabid, loc, null);
        return true;
    }

    close_channel(command) {
        if (!this.channels_enabled()) return;
        var tabid = this.channel_id_from_cmd(command);
        this.deactivate_tab (tabid);
    }

    close_channels() {
        for (var i = 0; i < this._channels.length; ++i) {
            var id = this._channels[i].tabid;
            this.deactivate_tab (this._channels[id].id());
        }
        this._channels = [];
    }


    should_gag_channel(channel) {
        if (!this.channels_enabled()) return false;

        if (!this._nexus.settings().gag_comm) return false;
        if (!(this.channel_active(channel) || this.tab_is_active("all_comm"))) return false;
        return true;
    }

    channel_buffer(command) {
        let tabid = this.channel_id_from_cmd(command);
        let rec = this.channel_record (tabid);
        if (!rec) return null;
        return rec.buffer;
    }

    write_channel(command, message)
    {
        // Appending to the channel as long as the tab exists at all, even if it is hidden or whatever.
        let line = { parsed_line: message };
        line.timestamp_ms = Util.get_time(true);
        line.timestamp = Util.get_time(false);
        let lines = [];
        lines.push (line);

        let tabid = this.channel_id_from_cmd(command);
        let rec = this.channel_record (tabid);
        if (rec) {
            try {
                rec.buffer.add_block (lines);
            } catch (e) { }
        }

        try {
            this._nexus.ui().comm_buffer().add_block (lines);
        } catch (e) { }
    };

    // *** CHANNEL TABS - END ***

    // *** CONTENT WINDOWS ***
    
    create_content_window(id, name, type, content, width, height)
    {
        // check if it exists
        let found = false;
        let dlg = null;
        if (!this.layout) return;
        let cwins = this.layout.state.contentwindows;
        for (let widx = 0; widx < cwins.length; ++widx) {
            dlg = cwins[widx];
            if (dlg.id !== id) continue;
            found = true;
            break;
        }

        if (!found) {
            dlg = {};
            dlg.open = true;
            dlg.id = id;
            dlg.name = name;
            dlg.type = type;
            cwins.push (dlg);
        }
        dlg.content = content;
        dlg.width = width;
        dlg.height = height;
        dlg.offset = this.layout._offsets;
        this.layout._offsets++;
        this.layout.setState({contentwindows: cwins});
    }

    close_content_window (id)
    {
        if (!this.layout) return;
        let cwins = this.layout.state.contentwindows;
        for (let widx = 0; widx < cwins.length; ++widx) {
            let dlg = cwins[widx];
            if (dlg.id !== id) continue;
            // this one needs to close
            cwins.splice(widx, 1);
            this.layout.setState({contentwindows: cwins});
            break;
        }

        let wins = this.layout.state.textwindows;
        if ((!wins.length) && (!cwins.length)) this.layout._offsets = 0;
    }
    
    // *** CONTENT WINDOWS - END ***
    
}
