
import { CreationBaseDialog } from './creationbase.js'
import { Util } from '../../base/util.js'

import React from 'react';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { GameSelector } from '../gameselector'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '../../css/creation.css'

export class CreationDialog extends CreationBaseDialog {
    constructor(props) {
        super(props);
    }

    // returns a promise
    fetchFile(url, params=undefined) {
        if (params) {
            let encoded = [];
            for (let pname in params) {
                encoded.push(pname+'='+encodeURIComponent(params[pname]));
            }
            url += '?' + encoded.join('&');            
        }
        return window.fetch(url, { headers: { 'Cache-Control': 'no-cache, no-store, must-revalidate', 'Pragma': 'no-cache', 'Expires': 0 }});
    }

    renderListItem(stage, type, e, disabled) {
        let names = this.element_split_name(e);
        let name = names[0];
        let sel = (this.state.selection[type] === e.id);
        let elImage = e.thumbImageURL ? (<img className='list_item_image' src={e.thumbImageURL} width='150px' />) : null;
        let elText = (<div className='list_item_text'>{name}</div>);
        let className = 'list_item';
        if (disabled) className += ' item_disabled';
        let res = (<div key={type+'-'+e.id} className={className} onClick={(ev)=>{ if (!disabled) this.onListItemClick(type, e); } } >{elImage}{elText}</div>);
        if (disabled) res = (<Tooltip key={type+'-'+e.id} title={'Your selected class cannot join ' + name + '.'}>{res}</Tooltip>);
        return res;
    }

    renderListDetails(type, e) {
        let t = this;

        let next_caption = this.nextButtonCaption(false);
        let btn_next_class = 'next_button' + (this.state.failed ? ' item_disabled' : '');
        let btn_next = (<div><div className={btn_next_class} key='next' onClick={() => { if (!this.state.failed) this.btnNext(); }} >{next_caption}{'  '}<FontAwesomeIcon icon={['fas', 'arrow-right']} /></div></div>);
        let btn_back = (<div className='close_button' key='back' onClick={() => this.btnBackToList()} ><FontAwesomeIcon icon={['fas', 'xmark']} /></div>);

        let title = this.element_title (type, e);
        let title_div = (<div className='creation_details_title'>{title}</div>);
        let subtitle = this.element_subtitle (type, e);
        let subtitle_div = subtitle ? (<div className='creation_details_subtitle'>{subtitle}</div>) : null;
        let gender = this.element_genderreq(type, e);
        let gender_div = gender ? (<div className='restriction_warning'>{this.element_genderreq_text(type, e)}</div>) : null;
        let restrict = this.element_restriction(type, e);
        let restrict_div = restrict ? (<div className='restriction_warning'>{this.element_restriction_text(type, e)}</div>) : null;
        let line = (<div style={{ borderTop:'1px solid white', marginTop: '5px', marginBottom: '5px' }}></div>);

        let image = (<img src={e.imageURL} alt='' className='creation_details_image' />);
        image = (<div className='creation_details_image_wrapper'>{image}</div>);

        let descs = [];
        if (e.blurb) descs = e.blurb.split ("\n");
        else if (e.desc) descs = e.desc.split ("\n");
        let descs_el = [];

        for (let idx = 0; idx < descs.length; ++idx)
        {
            descs_el.push ((<span key={'desc'+idx} dangerouslySetInnerHTML={{__html:'<span>'+descs[idx]+'</span>'}}></span>));
            descs_el.push ((<br style={{margin:'8px'}} key={'br'+idx} />));
        }
        let desc = (<div className='creation_details_text'>{title_div}{subtitle_div}{gender_div}{restrict_div}{btn_next}{line}{descs_el}</div>);

        let center = (<div className='creation_details_center'>{image}{desc}{btn_back}</div>);
        return (<div className='creation_details_main'>{center}</div>);
    }

    renderList(stage, type) {
        if (!this.state.data) return null;
        let items = this.state.data[type];
        if (!items) items = [];

        let selitem = null;
        for (let i = 0; i < items.length; ++i)
        {
            let e = items[i];
            let id = e.id;
            if (this.state.selection[type] === id) selitem = e;
        }

        let center;
        if (selitem) {
            let item_details = this.renderListDetails(type, selitem);
            center = (<div key={type + '_details'} id={type + '_details'} className='creation_details'>{item_details}</div>);
        } else {
            let grouplist = [];
            let listitems = [];
            let groups = {};
            for (let i = 0; i < items.length; ++i)
            {
                let e = items[i];
                let id = e.id;
                let disabled = false;
                if (!this.valid_choice(type, id)) disabled = true;

                let item = this.renderListItem(stage, type, e, disabled);

                var grp = this.display_list_group(e, type);
                if (grp) {
                    if (!groups[grp]) groups[grp] = [];
                    groups[grp].push (item);
                } else
                    listitems.push (item);
            }
            grouplist.push((<div className='creation_list_entries'>{listitems}</div>));

            // append the elements from each group
            for (let g in groups) {
                grouplist.push((<div className='creation_list_group'>{g}</div>));
                listitems = [];
                for (let j = 0; j < groups[g].length; ++j)
                    listitems.push (groups[g][j]);
                grouplist.push((<div className='creation_list_entries'>{listitems}</div>));
            }

            center = (<div id={type + '_list'} className='creation_list'>{grouplist}</div>);
        }

        let header_text = this.creation_title(stage);
        let list_header = (<div id={type + '_list_header'} className='creation_list_header'>{header_text}</div>);

        let subheader_text = this.creation_stage_info(type);
        let list_subheader = (<div id={type + '_list_subheader'} className='creation_list_subheader'>{subheader_text}</div>);
            
        let steps = this.renderCreationSteps();
        let res = (<div className='creation_list_main'><div className='creation_list_center'>{steps}{list_header}{list_subheader}{center}</div></div>);
        return res;
    }

    renderCreationSteps() {
        var steps = [];
        var back = (<span className='creation_step' style={{cursor:'pointer'}} onClick={() => this.btnBack()}><FontAwesomeIcon icon={['fas', 'arrow-left']} /><span> Go back a step</span></span>);
        for (let idx = 1; idx <= this.stages(); ++idx) {
            let title = idx+'. '+this.creation_short_title (idx);
            let className = 'creation_step';
            if (idx == this.state.stage) className += ' creation_step_current';
            steps.push(<span className={className}>{title}</span>);
        }
        
        steps = (<div class="creation_steps">{back}{steps}</div>);
        return steps;
    }

    nameform_element(key, el) {
        return (<div key={key} style={{marginBottom:'5px'}}>{el}</div>);
    }

    nameform_label(key, label) {
        let el = (<FormLabel component="legend">{label}</FormLabel>);
        return this.nameform_element(key, el);
    }

    renderNameForm(stage) {
        let t = this;
        let name_error = t.state.nameAvailMessage;
        if (!name_error) name_error = t.state.nameMessage;
        let name_el = (<TextField autoFocus style={{minWidth:'420px'}} key="charname" label="" FormHelperTextProps={{component:'div'}} value={this.state.name} onChange={(e)=>t.onNameChange(e.target.value, 'name')} required={true} error={t.state.errorName || t.state.errorNameAvail} helperText={name_error || ' '} />);
        let name_hint = (<span className='creation_name_hint'>{t.creation_name_hint()}</span>);
        let generate_el = (<Button variant='contained' style={{width:'180px', height:'58px', backgroundColor:'white', color:'#E23505', fontWeight: 700}} onClick={()=>t.generateName()}>Random Name</Button>);

        let pass_el = (<TextField key="pass" label="" FormHelperTextProps={{component:'div'}} type='password' value={this.state.pass} onChange={(e)=>t.onNameChange(e.target.value, 'pass')} required={true} error={t.state.errorPass} helperText={t.state.passMessage || ' '} />);
        let pass2_el = (<TextField key="pass2" label="" FormHelperTextProps={{component:'div'}} type='password' value={this.state.pass2} onChange={(e)=>t.onNameChange(e.target.value, 'pass2')} required={true} error={t.state.errorPass2} helperText={t.state.pass2Message || ' '} />);
        let email_el = (<TextField key="email" label="" FormHelperTextProps={{component:'div'}} value={this.state.email} onChange={(e)=>t.onNameChange(e.target.value, 'email')} required={true} error={t.state.errorEmail} helperText={t.state.emailMessage || ' '} />);
        let tos_text = (<span>I agree to the <a href={'https://'+t.props.nexus.gameinfo().server_name()+'/tos'} target='_blank' rel='noopener noreferrer'>Terms of Service</a> *</span>);
        let tos_el = (<FormControlLabel label={tos_text} control={<Checkbox checked={t.state.tos} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'tos')} />} />);
        let nl_game_text = 'Send me the '+this.props.nexus.gameinfo().game_short()+' newsletter.';
        let nl_game_el = (<FormControlLabel label={nl_game_text} control={<Checkbox checked={t.state.nl_game} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'nl_game')} />} />);
        let nl_ire_text = 'Send me the Iron Realms newsletter.';
        let nl_ire_el = (<FormControlLabel label={nl_ire_text} control={<Checkbox checked={t.state.nl_ire} onChange={(e)=>t.onNameCheckbox(e.target.checked, 'nl_ire')} />} />);

        let els = [ ];
        let name_label = this.nameform_label('name_label', 'Choose Your Character Name');
        let name = this.nameform_element('el_name', (<div>{name_el}{generate_el}<br />{name_hint}</div>));
        //let generate = this.nameform_element('el_generate', generate_el);
        els.push(name_label);
        els.push(name);
        //els.push(generate);

        let gender;
        if (this.gender_enforced()) {
            gender = this.gender_enforced_text();
        } else {
            // gender selector
            let g = this.genders();
            let glist = [];
            for (let idx = 0; idx < g.length; ++idx)
                glist.push((<FormControlLabel key={'gender-'+idx} value={g[idx]} control={<Radio />} label={g[idx]} />));
            gender = (<RadioGroup row value={t.state.gender} area-label='gender' onChange={(e)=>t.onGenderChange(e.target.value)} >{glist}</RadioGroup>);
        }
        gender = this.nameform_element('el_gender', gender);
        let gender_label = this.nameform_label('gender_label', 'Gender');
        els.push(gender_label);
        els.push(gender);

        let pass = this.nameform_element('el_pass', pass_el);
        let pass_label = this.nameform_label('pass_label', 'Password');
        let pass2 = this.nameform_element('el_pass2', pass2_el);
        let pass2_label = this.nameform_label('pass2_label', 'Confirm password');
        els.push(pass_label);
        els.push(pass);
        els.push(pass2_label);
        els.push(pass2);

        let age, age_label;
        if (t.show_age()) {
            let limits = t.age_limits();
            let age_el1 = (<Slider value={t.state.age} className='creation_age_slider' min={limits[0]} max={limits[1]} step={1} onChange={(e,val)=>t.onAgeChange(val)} />);
            let age_el2 = (<Input value={t.state.age} className='creation_age_input' onChange={(e)=>t.onAgeInput(e.target.value)} onBlur={(e)=>t.onAgeInputBlur(e.target.value)} inputProps={{min:limits[0],max:limits[1],type:'number'}}/>);
            age = this.nameform_element('el_age', (<div>{age_el1}<br />{age_el2}</div>));
            age_label = this.nameform_label('age_label', 'Choose the starting age of your character');
            els.push(age_label);
            els.push(age);
        }
        let email_info = '';
        if (t.state.emailChecked) {
            if (t.state.emailRegistered) email_info = 'Your character will be added to the existing registration.';
            else email_info = 'This is a new registration. Please enter your information below.';
        } else {
            if (t.emailcheck_running) email_info = 'Checking your registration ...';
            else email_info = '';
        }

        let email = this.nameform_element('el_email', (<div style={{marginBottom:'15px'}}>{email_el}<br />{email_info}</div>));
        let email_label = this.nameform_label('email_label', 'E-mail');
        els.push(email_label);
        els.push(email);

        // Do we want to show regis data?
        if (t.state.emailChecked && (!t.state.emailRegistered)) {
            let firstname_el = (<TextField key="firstname" label="" FormHelperTextProps={{component:'div'}} value={this.state.firstName} onChange={(e)=>t.onNameChange(e.target.value, 'firstName')} required={true} error={t.state.errorFirstName} />);
            let firstname = this.nameform_element('el_firstname', firstname_el);
            let firstname_label = this.nameform_label('firstname_label', 'First Name');
            els.push(firstname_label);
            els.push(firstname);

            let lastname_el = (<TextField key="lastname" label="" FormHelperTextProps={{component:'div'}} value={this.state.lastName} onChange={(e)=>t.onNameChange(e.target.value, 'lastName')} required={true} error={t.state.errorLastName} />);
            let lastname = this.nameform_element('el_lastname', lastname_el);
            let lastname_label = this.nameform_label('lastname_label', 'Last Name');
            els.push(lastname_label);
            els.push(lastname);

            let dob_el = (<TextField key="dob" label="" FormHelperTextProps={{component:'div'}} value={this.state.DOB} onChange={(e)=>t.onNameChange(e.target.value, 'DOB')} required={true} error={t.state.errorDOB} helperText={t.state.DOBMessage || ' '}/>);
            let dob = this.nameform_element('el_dob', dob_el);
            let dob_label = this.nameform_label('dob_label', 'Date of Birth');
            els.push(dob_label);
            els.push(dob);
        }

        let bottom = [];
        let tos = this.nameform_element('el_tos', tos_el);
        bottom.push(tos);
        let nl_game = this.nameform_element('el_nl_game', nl_game_el);
        bottom.push(nl_game);
        let nl_ire = this.nameform_element('el_nl_ire', nl_ire_el);
        bottom.push(nl_ire);

        let next_caption = this.nextButtonCaption(false);
        let btn_next_class = 'next_button' + (this.state.failed ? ' item_disabled' : '');
        let btn_next = (<div className={btn_next_class} style={{textAlign: 'right'}} key='next' onClick={() => { if (!this.state.failed) this.btnNext(); }} >{next_caption}{'  '}<FontAwesomeIcon icon={['fas', 'arrow-right']} /></div>);

//        let btn_next = this.nameform_element('btn_next', (<div style={{textAlign: 'right'}}>{this.renderNextButton(true)}</div>));
        bottom = (<div style={{display:'flex',flexDirection:'row',width:'600px', alignItems:'end'}}><div style={{flex:1}}>{bottom}</div><div style={{width:'240px', textAlign:'right'}}>{btn_next}</div></div>);
        els.push(bottom);

        let textlines = this.creation_nameform_text().split("\n");
        let textlines_els = [];
        for (let idx = 0; idx < textlines.length; ++idx) {
            if (idx) textlines_els.push ((<br/>));
            textlines_els.push (textlines[idx]);
        }
        let subheader = (<div className='creation_list_subheader'>{textlines_els}</div>);


//        let autofill = this.nameform_element('autofill', (<div>&nbsp;</div>), row, 1);

        let steps = this.renderCreationSteps();
        let main = (<div className='creation_name_main'>{els}</div>);
        let header = (<h2 className='creation_stage_title'>{this.creation_title(stage)}</h2>);
        let res = (<div className='creation_name_wrapper'>{steps}{header}{subheader}{main}</div>);
        return res;
    }

    renderGameSelector() {
        let nex = this.props.nexus;
        let chars = this.props.chars;
        let logged = nex.logged_in_nexus();
        let landscape = nex.platform().is_landscape();  // needed so that the dialog updates when we rotate the device
        let sel = (<GameSelector nexus={nex} landscape={landscape} chars={chars} loggedin={logged} onSelect={ (gameID, info) => this.onGameSelect(gameID, info) } />);
        return sel;
    }

    renderBackButton(lastPage=false) {
        let style = {minWidth: 150};
        return (<Button key='back' variant="contained" style={style} onClick={() => this.btnBack()} >Back</Button>);
    }

   
    renderNextButton(lastPage=false) {
        let t = this;
        let next_caption = this.nextButtonCaption(false);

        let style = {minWidth: 150};
        return (<Button key='next' variant="contained" style={style} onClick={() => t.btnNext()} color="secondary" disabled={this.state.failed}>{next_caption}</Button>);
    }

    renderHeader()
    {
        let nex = this.props.nexus;
        let buttons = [];
        let style = {color:'black', backgroundColor: '#d7d7d7', marginRight:'10px', fontSize:'0.6em'};
        let res = (<div style={{display:'flex', flexDirection:'row'}}><span style={{flex:1}}>{'NEXUS'}</span></div>);
        return res;
    }

    render() {
        let t = this;
        let stage = t.state.stage;

        let subtitle = null;
        let content = null;

        let title = t.renderHeader();
        if (t.state.loadFailed) {
            subtitle = 'Loading failed';
            content = (<div className='creation_loading'><div>{t.state.loadFailed}</div><div>{this.renderBackButton()}</div></div>);
        } else if (!t.state.data) {
            subtitle = 'Character Creation';
            content = 'Loading ...'
            content = (<div className='creation_loading'><div>Loading ...</div><div>{this.renderBackButton()}</div></div>);
        } else {
            content = this.renderContent(stage);
        }

        let games = this.renderGameSelector();
        games = (<div className='creation_list_games'><div className='creation_list_games_inner'>{games}</div></div>);

        if (subtitle) subtitle = (<div className='creation_subtitle'>{subtitle}</div>);
        content = (<div className='creation_right'>{subtitle}{content}</div>);
        content = (<div className='creation_content'>{games}{content}</div>);

        let nex = this.props.nexus;
        let background = nex.ui().layout().backgroundImage();
        let backdropStyle={backgroundSize: '75% auto', backgroundColor: 'black', backgroundRepeat: 'no-repeat', backgroundPosition: 'top right', backgroundImage: 'url("'+background+'")'};
        return (
            <Dialog
                className='creation_dialog'
                fullScreen
                open={this.props.open}
                aria-labelledby="creation-dialog"
                PaperProps={{style: {backgroundColor: 'rgba(0,0,0,0.4)'} }}
                disableEscapeKeyDown={true}
                BackdropProps={{style:backdropStyle}}>
            <DialogTitle id="creation-dialog">{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            </Dialog>
        );
    }

}
