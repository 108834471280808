import React from 'react'

import Tooltip from '@mui/material/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { GameSelectorBase } from './gameselector_base'

export class GameSelector extends GameSelectorBase {

    onGameListLoaded() {
        // Need to re-select the current game too, in case it got changed
        // This is only done in the web version, not on mobile.
        let nex = this.props.nexus;
        let gameID = nex.active_game_id();
        if (gameID > 0) {
            let g = this.getGame(gameID);
            this.onGameSelect(gameID, g);
        }
    }

    renderGamePicker(gameID, caption, subtitle, info, cur, deletion, gi) {
        let buttons;
        if (deletion) {
            let iconstyle = {height:'22px', width:'22px', paddingLeft:'5px'};
            let btn_settings = (<FontAwesomeIcon className='game_rename' style={iconstyle} icon={['fad', 'pen']} aria-label="Update" onClick={() => this.onGameUpdate(gameID)} />);
            btn_settings = (<Tooltip title={'Edit Settings'}><span>{btn_settings}</span></Tooltip>);
            let btn_delete = (<FontAwesomeIcon className='game_delete' style={iconstyle} icon={['fad', 'trash-can']} aria-label="Remove" onClick={() => this.onGameRemove(gameID)} />);
            btn_delete = (<Tooltip title={'Delete'}><span>{btn_delete}</span></Tooltip>);
            buttons = (<div className='gamesel_expanded'>{btn_settings}{btn_delete}</div>);
        }
        
        let titlesStyle = { 'display' : 'flex', 'flex-direction' : 'column', 'flex' : 1 };
        caption = (<div key='gamesel_name' className='gamesel_name'>{caption}</div>);        
        if (subtitle) subtitle = (<div key='gamesel_subtitle' className='gamesel_subtitle'>{subtitle}</div>);
        let titles = (<div className='gamesel_expanded' style={titlesStyle}>{caption}{subtitle}</div>);
        let url = gi ? gi.files_url() : null;
        let icon = url ? (<div><img src={url + '/images/logo-sidebar.png'} height={40} style={{margin:'8px', display: 'block'}} /></div>) : null;

        let contentStyle = { 'display' : 'flex', 'flex-direction' : 'row' };
        let content = (<div key='gamesel_entry' style={contentStyle}>{icon}{titles}{buttons}</div>);

        let classes = 'gamesel_entry';
        if (cur) classes += ' current';

        return ((<div key={'gamesel_'+gameID} className={classes} onClick={()=>this.onGameSelect(gameID, info)}>{content}</div>));
    }

    renderButtonAdd() {
        let iconStyle = {height:'40px', width:'40px', marginRight:'10px', verticalAlign: 'middle', color: 'white', backgroundColor: 'black'};
        let btn_plus = (<FontAwesomeIcon icon={['fas', 'square-plus']} style={iconStyle} />);
        btn_plus = (<div key='gamesel_add' className='gamesel_add' onClick={()=>this.onGameAdd()} style={{textTransform:'none'}}>{btn_plus}<span className='gamesel_bottom_text gamesel_expanded'>{'Add Game'}</span></div>);
        let btn_help = (<FontAwesomeIcon icon={['fas', 'square-question']} style={iconStyle} />);
        btn_help = (<div key='gamesel_help' className='gamesel_add' onClick={()=>this.onhelp()} style={{textTransform:'none'}}>{btn_help}<span className='gamesel_bottom_text gamesel_expanded'>{'Help'}</span></div>);

        let store_style = { 'margin' : '5px' };
        let img_apps_folded = (<img src="/images/login/apps.png" height={90}/>);
        let img_google = (<img src="/images/bottom/play-store.png" style={store_style} />);
        let img_ios = (<img src="/images/bottom/apple-store.png" style={store_style} />);
        img_google = (<a href="https://play.google.com/store/apps/details?id=com.ironrealms.nexus" target="_blank">{img_google}</a>);
        img_ios = (<a href="https://apps.apple.com/us/app/nexus-mud-client/id1641739522" target="_blank">{img_ios}</a>);
        let apps = (<div style={{textAlign:'center', height:'90px'}}><div className='gamesel_folded' style={{textAlign:'left'}}>{img_apps_folded}</div><div className='gamesel_expanded'>{img_google}{img_ios}</div></div>);

        let buttons = (<div key={'game_buttons'}>{btn_plus}{btn_help}{apps}</div>);
        return ((<div key={'game_add'} className='gamesel_bottom'>{buttons}</div>));
    }

    renderHeader(text) {
        return (<div key={'select'+text} className='gamesel_header'><span className='gamesel_expanded'>{text}</span></div>);
    }

    renderGamesList(els) {
        return (<div className='gamesel_entries'>{els}</div>);
    }

    renderSpace() {
        return (<div key='space' style={{height:'50px'}}>&nbsp;</div>);
    }

    render() {
        let content = this.renderContent();
        let res = (<div className='gameSelector' style={this.props.style || {}}>{content}</div>);
        return res;
    }
}
 
