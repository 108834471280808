
// game info - Lusternia

import { Util } from '../../base/util.js'

export class GameInfoLusternia {
    constructor() {

        this._gauges = [];
        this._gauges.push ({ name : 'health', 'desc' : 'Health', curvar : 'hp', maxvar : 'maxhp', percentage: true, color : '#ff0000', coloroff: '#7f7f7f',
            tooltip : 'Health represents your physical well-being. When your health reaches 0 you will die.'
        });
        this._gauges.push ({ name : 'mana', 'desc' : 'Mana', curvar : 'mp', maxvar : 'maxmp', percentage: true, color : '#5050ff', coloroff: '#7f7f7f',
            tooltip : 'Mana represents mental strength. When you are out of mana, you will not be able to perform most mental actions.'
        });
        this._gauges.push ({ name : 'ego', 'desc' : 'Ego', curvar : 'ego', maxvar : 'maxego', percentage: true, color : '#50ff50', coloroff: '#7f7f7f',
            tooltip : 'Your ego is the state of health of your personality. It is based off of your charisma and used in ego battles with the denizens of Lusternia.'
        });
        this._gauges.push ({ name : 'power', 'desc' : 'Power', curvar : 'pow', maxvar : 'maxpow', percentage: false, color : '#ff7f50', coloroff: '#7f7f7f',
            tooltip : 'Power is a special form of energy required for special feats. Players may fill their reserves at a nexus of power.'
        });
        
        this._balances = [];
        this._balances.push ({name : 'balance', class : 'bal', desc : 'Balance', curvar : 'balance',
            tooltip : 'Most physical abilities require that you have Balance and will take away your Balance for a few seconds after you use the ability.'
        });
        this._balances.push ({name : 'equilibrium', class : 'eq', desc : 'Equilibrium', curvar : 'equilibrium',
            tooltip : 'Most magical or mental abilities will require that you have \'Equilibrium\' (or simply \'eq\') and will use it up for a few seconds when you use the ability.'
        });
    }

    server_name(secondary = false) {
        if (secondary) return 'ironrealms.com';
        return 'lusternia.com';
    }

    server_port(encrypted, secondary = false) {
        if (secondary) return 12003;
        if (encrypted) return 443;
        return 80;
    }
    
    is_ire_game() { return true; }
    websock_dir() { return '/socket/'; }
    game_short() { return 'Lusternia'; }
    game_name() { return 'lusternia'; }
    game_long() { return 'Lusternia, Age of Ascension'; };
    root_url() { return 'https://www.'+this.server_name(false)+'/' };
    vote_url() { return this.root_url()+'vote' };
    password_url() { return this.root_url()+'game/recover' };
    script_url() { return 'https://client.'+this.server_name(false)+'/' };
    files_url() { return '/games/' + this.game_name(); }
    text_creation() { return '2'; }
    button_count() { return 6; }
    css_style() { return 'standard'; }
    gauges() { return this._gauges; }
    balances() { return this._balances; }
    rift_name() { return 'Rift'; }
    command_rift_in() { return 'inr'; }
    command_rift_out() { return 'outr'; }
    money() { return 'Gold'; }
    intro_sound() { return 'lusternia'; }
    avatar_count() { return 38; }
    discord_url() { return 'https://discord.gg/d8PECxD739'; }
    facebook_url() { return 'https://www.facebook.com/Lusternia'; }
    twitter_url() { return 'https://twitter.com/Lusternia'; }

    google_analytics() { return 'UA-21407589-1'; }
    
    default_tabs() {
        return {
            container_1: [ 'map' ],
            container_2: [ 'affdef', 'skills', 'inventory' ],
            container_3: [ 'room', 'who', 'news' ],
            container_4: [ 'quests', 'all_comm', 'achievements' ],
            disabled: [ 'gauges', 'buttons' ],
            main_container: [ "output_main" ]
        };
    }

    // creation
    creation_stages() { return 2; }
    creation_valid_choice(type, id, creation_dlg) { return true; }
    
    creation_step(step) {
        if (step === 1) return 'city';
        if (step === 2) return 'name';
        return '';
    }

    creation_short_title(step) {
        if (step === 1) return 'City';
        if (step === 2) return 'Description';
        return '';
    }

    creation_title(step) {
        if (step === 1) return 'Select Your Faction';
        if (step === 2) return 'Describe Your Character';
        return '';
    }

    creation_prepare_data(data) {
        let url = 'https://www.lusternia.com/local/nexus_data/';

        for (let c in data['city']) {
            let img = data['city'][c].imageURL;
            data['city'][c].imageURL = url + "cities/" + img;
            data['city'][c].mobileImageURL = url + "cities/" + img;
            data['city'][c].thumbImageURL = url + "cities/thumbnails/" + img;
        }
        Util.fisherYatesShuffle(data['city']);  // randomise cities
        return data;
    }

    genders() { return [ 'Male', 'Female', 'Non-Binary' ]; }
    show_age() { return true; }
    age_limits(data, creation_dlg) { return [ data.ageLowerLimit, data.ageUpperLimit, 18 ]; }

    creation_genderreq (type, e) {
        if (type !== 'race') return null;
        let req = e.genderReq;
        if (req === 0) return null;
        let list = this.genders();
        return list[req-1];
    }
    creation_genderreq_text(type, e) {
        let g = this.creation_genderreq(type, e);
        if (!g) return '';
        return 'Your race is comprised only of ' + g + 's.';
    }

    creation_data(creation_dlg) {
        let city = creation_dlg.selected_obj('city');
        if (!city) return null;
        let age = creation_dlg.state.selection['age'];
        return "age\n" + age + "\ncityID\n" + city.id;
    }

    creation_nameform_image(data, creation_dlg) {
        let race = creation_dlg.selected_obj('city');
        if (!race) return null;
        return race.imageURL;
    }

    creation_nameform_text(creation_dlg) {
        let city = creation_dlg.selected_obj('city');
        return 'You belong to ' + city.name + '.';
    }

    backgroundImage() {
        return this.files_url() + '/images/background.jpg';
    }

}
